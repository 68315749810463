import { DOCUMENT } from '@angular/common';
import { Component ,PLATFORM_ID, Inject, InjectionToken, LOCALE_ID, AfterContentInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { MetaSeoService } from './core/services/meta-seo.service';

export const HOST_ID = new InjectionToken<string>('host');
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements AfterContentInit{
  constructor(
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LOCALE_ID) private localId: Object,
    @Inject(DOCUMENT) private document: Document,
    private seoService:MetaSeoService,
  ) {
    translate.addLangs(['en', 'tr']);
  }
  ngAfterContentInit(): void {
    window.onload=()=>{
      var link = document.createElement("link")
      link.rel = "stylesheet"
      link.type = "text/css"
      link.href = "styles.css"
      document.head.appendChild(link);
    }
    
  }
    ngOnInit() {
      if (isPlatformBrowser(this.platformId)) {
        this.seoService.setMetaAndTitleDefault()
        this.configLanguage();
       
      }
  }

  configLanguage() {    
      if(['en','tr'].indexOf(this.localId+"")>-1){
        this.translate.setDefaultLang(this.localId+"")
      }else{
        this.translate.setDefaultLang('tr')
      }      
      this.document.documentElement.lang =this.translate.getDefaultLang();    
  }
  
  faThumbsUp=faThumbsUp;
  faThumbsDown=faThumbsDown;
}