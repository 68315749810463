import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CalculateModule } from './calculate/calculate.module';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export function getRequest(): any {
  return { headers: { cookie: document.cookie } };
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'halkaarzui' }),
    AppRoutes,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CalculateModule
  ],
  providers: [
    DatePipe,
    NgbActiveModal,
    {
      // The server provides these in main.server
      provide: REQUEST,
      useFactory: getRequest,
    },
    { provide: 'ORIGIN_URL', useValue: LOCALE_ID},],
  bootstrap: [AppComponent]
})
export class AppModule { }
